import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { useIntl } from 'gatsby-plugin-intl'
import styled from '@emotion/styled'

import ResponsiveTitle from '../components/ResponsiveTitle'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { TABLET_MIN_BREAKPOINT, WIDESCREEN_MIN_BREAKPOINT } from '../constants/breakpoints'

const TitleWrapper = styled.div`
  padding: 0 0 2.5rem 0;
`

const ContentWrapper = styled.div`
  background-color: transparent;
  padding-top: 1rem;
  padding-bottom: 10rem;
`

const StyledContent = styled.div`
  padding: 1.5rem 0;

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    padding: 1.5rem 5rem;
  }
`

const InsetImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -8rem;
`

const InsetImageWrapper = styled.div`
  width: 80%;

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    width: 50%;
  }
  @media screen and (${WIDESCREEN_MIN_BREAKPOINT}) {
    width: 30%;
  }
`

export const AboutPageTemplate = ({ image }) => {
  const { formatMessage } = useIntl()
  return (
    <Grid fluid>
      <Col xs={12}>
        <TitleWrapper>
          <ResponsiveTitle title={formatMessage({ id: 'about.title' })} />
        </TitleWrapper>
      </Col>
      <Col xs={12}>
        <Row>
          <ContentWrapper className="box has-box-shadow">
            <StyledContent className="content has-link-inline">
              <p>{formatMessage({ id: 'about.p1' })}</p>
              <p>{formatMessage({ id: 'about.p2' })}</p>
              <p>
                {formatMessage(
                  { id: 'about.p3 | wildlifeLink, rhinoRevolutionItalic' },
                  {
                    wildlifeLink: (
                      <Link to="/portfolio/wildlife">
                        {formatMessage({ id: 'portfolio.wildlife' })}
                      </Link>
                    ),
                    rhinoRevolutionItalic: (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.rhinorevolution.org/"
                      >
                        <em>Rhino Revolution</em>
                      </a>
                    ),
                  }
                )}
              </p>
              <p>{formatMessage({ id: 'about.p4' })}</p>
            </StyledContent>
            {/* TODO: add socials under about img */}
          </ContentWrapper>
        </Row>
        <Row>
          <InsetImageContainer>
            <InsetImageWrapper>
              <PreviewCompatibleImage imageInfo={image} />
            </InsetImageWrapper>
          </InsetImageContainer>
        </Row>
      </Col>
    </Grid>
  )
}

AboutPageTemplate.propTypes = {
  image: PropTypes.object,
}

const AboutPage = ({ data }) => {
  const { markdownRemark } = data

  return (
    <Layout>
      <AboutPageTemplate
        title={markdownRemark.frontmatter.title}
        content={markdownRemark.html}
        image={markdownRemark.frontmatter.image}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 526, quality: 92) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
